import { HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular/standalone';
import { InstaChatProperty } from '@pixels/client/anayltics/analytics.model';
import { AnalyticsService } from '@pixels/client/anayltics/analytics.service';
import { ChatAuthService } from '@pixels/client/chat-auth/chat-auth.service';
import { isEmptyForInstaChatUserByOneOnOneType } from '@pixels/client/one-on-one/core/constant';
import { InstaChatUserApiService } from '@pixels/client/one-on-one/core/services/insta-chat-user-api.service';
import { AppInfo } from '@pixels/client/pixels/app-info';
import { retryCountExcludedStatusCodes } from '@pixels/client/utils/rx-retry';
import { isEmpty } from '@pixels/universal/es-toolkit-ext';
import { InstaChatUserForUserInfo } from '@pixels/universal/model/insta-chat/insta-chat-user/insta-chat-user.schema';
import { catchError, forkJoin, map, Observable, of, switchMap, throwError } from 'rxjs';

export function needUpdateInstaChatUserGuard(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
  const instaChatUserApiService = inject(InstaChatUserApiService);
  const chatAuthService = inject(ChatAuthService);
  const analytics = inject(AnalyticsService);
  const navCtrl = inject(NavController);
  const usernameSettingPath = inject(AppInfo).usernameSettingPath;
  const appType = inject(AppInfo).appType;
  const worked = false;
  if (worked) {
    return of(true);
  }
  return instaChatUserApiService.fetchMe().pipe(
    retryCountExcludedStatusCodes({
      maxRetryAttempts: 5,
      scalingDuration: 300,
      excludedStatusCodes: [
        HttpStatusCode.BadRequest, HttpStatusCode.Forbidden, HttpStatusCode.NotFound, HttpStatusCode.Unauthorized
      ]
    }),
    catchError(err => {
      if ([HttpStatusCode.Unauthorized].includes(err.status)) {
        return of(undefined);
      }
      return throwError(() => err);
    }),
    switchMap((user: InstaChatUserForUserInfo | undefined) => {
      if (!user) {
        return of(true);
      }
      if (isEmpty(user.gender) || isEmpty(user.user.username) || isEmptyForInstaChatUserByOneOnOneType(user, appType)) {
        navCtrl.navigateRoot(usernameSettingPath, { queryParams: { returnUrl: state.url }, replaceUrl: true });
        return of(false);
      }

      return forkJoin([
        analytics.setUserProperty({ key: InstaChatProperty.location, value: user.location as string }),
        analytics.setUserProperty({ key: InstaChatProperty.birthYear, value: user.birthday ? new Date(user.birthday).toISOString() : '' }),
        analytics.setUserProperty({ key: InstaChatProperty.gender, value: user.gender as string }),
        analytics.setUserProperty({ key: InstaChatProperty.imsoloName, value: user.imsoloName as string }),
        analytics.setUserProperty({ key: InstaChatProperty.mbti, value: user.mbti as string }),
        analytics.setUserProperty({ key: InstaChatProperty.artist, value: user.artist as string }),
        analytics.setUserProperty({ key: InstaChatProperty.countryCode, value: user.countryCode as string }),
      ]).pipe(map(() => true));
    }),
    catchError(err => {
      console.log(err);
      return chatAuthService.logout().pipe(
        map(() => false)
      );
    }),
  );
}
