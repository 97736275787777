import { inject } from '@angular/core';
import { Auth, authState } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NavController, Platform } from '@ionic/angular/standalone';
import { IonicNativeAuthService } from '@pixels/client/capacitor/ionic-native-auth.service';
import { AppInfo } from '@pixels/client/pixels/app-info';
import { catchError, defer, map, Observable, of, switchMap, take } from 'rxjs';

export function authGuard(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
  const auth = inject(Auth);
  const navCtrl = inject(NavController);
  const loginPath = inject(AppInfo).loginPath;
  const isNative = inject(Platform).is('hybrid');
  const ionicNativeAuthService = inject(IonicNativeAuthService);

  return authState(auth).pipe(
    take(1),
    switchMap(user => {
      if (user) {
        return of(true);
      }

      // sign out 상태에서는 ionic-native-auth를 사용하여 로그아웃도 함께 처리 필요
      return defer(() => isNative ? ionicNativeAuthService.signOut().pipe(catchError(err => of(undefined))) : of(undefined)).pipe(
        switchMap(() => defer(() => navCtrl.navigateRoot(loginPath, { queryParams: { returnUrl: state.url } })).pipe(map(() => false)))
      );
    })
  );
}
